<template>
    <div class="photo-item-container">
        <div class="bg-container">
          <img src="@/assets/images/sample-image-three.jpg" alt="">
        </div>
        <div class="content-container">
            <div class="row-top">
              <el-popover placement="left-start" :width="150" trigger="click">
                <div class="photo-item-container__options flex-column">
                    <div class="option flex-row ai-fe">
                      <!-- <inline-svg class="download-icon" width="24" height="24" :src="item.icon"/> -->
                      <p class="label">Use as cover photo</p>
                    </div>
                    <div class="option flex-row ai-fe">
                      <!-- <inline-svg class="download-icon" width="24" height="24" :src="item.icon"/> -->
                      <p class="label">Delete</p>
                    </div>
                </div>
                <template #reference>
                    <span class="photo-item-container__menu flex-row jc-c ai-c"><el-icon :size="15" color="rgba(12, 15, 74, 0.5)"><MoreFilled/></el-icon></span>
                </template>
            </el-popover>
            </div>
        </div>
    </div>
</template>
<script>
import { MoreFilled } from '@element-plus/icons';
import { ref } from 'vue';

export default {
  components: {
    MoreFilled
  },
  setup() {
    const isOpenToolTip = ref(false);

    function changeIsOpenToolTipState() {
      isOpenToolTip.value = !isOpenToolTip.value;
    }
    // function changeActiveIndex(id) {
    //   emit('update-active-index', id);
    //   changeIsOpenToolTipState();
    // }

    return {
    //   changeActiveIndex,
      changeIsOpenToolTipState,
      isOpenToolTip
    };
  },
};
</script>
<style lang="scss" scoped>
.photo-item-container {
    width: 100%;
    height: 266px;
    position: relative;
    border-radius: 16px;
    cursor: pointer;

    .bg-container {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        z-index: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 16px;
        }
    }
    .content-container {
        position: relative;
        z-index: 2;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        height: 100%;
        width: 100%;
        border-radius: 16px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 1rem 1rem 2rem;
        box-sizing: border-box;

        .row-top {
            width: 100%;
            display:flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            gap:.5rem;
        }
    }
  &__menu {
    background: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
  }
  &__options {
    .option {
      cursor: pointer;
      padding: .4rem;
      border-radius: 4px;
      &:hover {
          background: rgba(255, 165, 0, 0.1);
          .options__label {
              color: #FFA500;
          }
      }

      &:last-child {
          position: relative;
          margin-top: 1rem;
          &::before {
              content: '';
              background: rgba(12, 15, 74, 0.2);
              position: absolute;
              top: -1rem;
              left:0;
              right:0;
              width: 100%;
              height: 1px;
          }
      }
    }

    .label {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: #0C0F4A;
      margin: 0;
    }
  }
}
</style>
