import { Plus, Upload } from '@element-plus/icons';
import { defineComponent, ref } from 'vue';
import AlbumItemComponent from './AlbumItemComponent.vue';
import PhotoItemComponent from './PhotoItemComponent.vue';
export default defineComponent({
    name: 'profile-gallery',
    components: {
        Plus,
        AlbumItemComponent,
        Upload,
        PhotoItemComponent
    },
    setup() {
        const albumCount = ref(10);
        const uploadedPhotoCount = ref(20);
        const isAddingAlbum = ref(false);
        const albumTitle = ref('');
        const isInGalleryPreview = ref(false);
        // const selectedActiveIndex = ref(null);
        const addAlbum = () => {
            isAddingAlbum.value = !isAddingAlbum.value;
        };
        const changeGalleryPreviewState = () => {
            isInGalleryPreview.value = !isInGalleryPreview.value;
        };
        // const changeSelectedActiveIndexState = (id) => {
        //   selectedActiveIndex.value = id;
        // };
        return {
            albumCount,
            isAddingAlbum,
            albumTitle,
            uploadedPhotoCount,
            addAlbum,
            changeGalleryPreviewState,
            isInGalleryPreview
            // selectedActiveIndex,
            // changeSelectedActiveIndexState,
        };
    }
});
